import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authSelector } from "../../auth/authSelector";
const PrivateRoute = (props) => {
  const authenticated = useSelector(authSelector, shallowEqual);
  return <>{authenticated ? props.children : <Navigate to="/" />}</>;
};

export default PrivateRoute;
