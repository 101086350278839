import axios from "axios";
import { apiUrl } from "../../config/apiConfig";

const endpoint = `${apiUrl}/api/product`;

export const getClientProducts = async (startTime, endTime) => {
  return await axios.get(
    `${endpoint}/client/1/available${
      (startTime !== null) & (endTime !== null)
        ? `?startDate=${startTime}&endDate=${endTime}`
        : ""
    }`
  );
};

export const addProduct = async (product) => {
  return await axios.post(`${endpoint}`, product);
};

export const getProducts = async () => {
  return await axios.get(`${endpoint}`);
};

export const getProductById = async (id) => {
  return await axios.get(`${endpoint}/${id}`);
};

export const searchProductByText = async (searchText) => {
  return await axios.get(`${endpoint}/client/1/search/${searchText}`);
};

export const searchStoreProductByText = async (storeId, searchText) => {
  return await axios.get(`${endpoint}/Store/${storeId}/Search/${searchText}`);
};

export const getProductByProductCode = async (productCode) => {
  return await axios.get(`${endpoint}/ProductCode/${productCode}/Search`);
};

export const saveProduct = async (id, product) => {
  product.clientId = 1;
  return await axios.put(`${endpoint}/${id}`, product);
};

export const createProduct = async (product) => {
  product.clientId = 1;
  return await axios.post(`${endpoint}`, product);
};

export const deleteProduct = async (id) => {
  return await axios.delete(`${endpoint}/${id}`);
};

export const deleteProducts = async (selected) => {
  return await axios.delete(`${endpoint}/delete`, { data: { data: selected } });
};

export const searchProducts = async (searchText, storeId) => {
  return await axios.get(`${endpoint}/store/${storeId}/search/${searchText}`);
};

export const searchProductsByStoreId = async (storeId) => {
  const data = await axios.get(`${endpoint}/store/${storeId}`);
  return data;
};

export const importProducts = async (products) => {
  return await axios.post(`${endpoint}/import`, { data: products });
};

export const getStockByProductId = async (productId) => {
  return await axios.get(`${endpoint}/${productId}/product-stock`);
};
