export const toCurrency = (number) => {
  const cleanNumber = String(number).replace(",", "");
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(cleanNumber);
};

export const parseToFloat = (numberString) => {
  const massagedString = numberString.replace(/,/g, "").replace("$", "");
  return parseFloat(massagedString);
};
