import { Typography } from "@mui/material";
import AddProductButton from "../AddProductButton/AddProductButton";
import currency from "currency.js";

const ProductCard = ({ product }) => {
  const base = "https://dmbstore.blob.core.windows.net/bouncehouse/images/";
  return (
    <div>
      <div className="flex items-center justify-center pt-16 pb-16 h-80 hover:shadow hover:border-2 hover:rounded-8">
        <img src={`${base}${product.imagePath}`} alt="bounce house" />
      </div>
      <div className="flex justify-between">
        <div>
          <Typography variant="h5">
            {`${currency(product.price).format()}/ day`}
          </Typography>
          <div>{product.name}</div>
          <div>{product.description}</div>
        </div>
        <div>
          {product?.isReserved ? (
            <div>
              <div className="text-red-500 pt-4 font-bold">UNAVAILABLE</div>
              <div className="text-red-500 text-xs">(change date)</div>
            </div>
          ) : (
            <AddProductButton product={product} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
