import { Button } from "@mui/material";
import SetEventTimeDialog from "./SetEventTimeDialog";
import { openDialog } from "../../store/applicationSlice";
import { useDispatch } from "react-redux";

const SetEventTimeButton = () => {
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(
      openDialog({
        children: <SetEventTimeDialog />,
      })
    );
  };

  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleOpen}>
        Set Event Time
      </Button>
    </div>
  );
};

export default SetEventTimeButton;
