import { createSelector } from "reselect";

const Products = (state) => state.products;

export const itemSelector = createSelector(
  Products,
  (Products) => Products.items
);

export const productSelector = createSelector(
  Products,
  (Products) => Products.selectedProduct
);

export const availableProductSelector = createSelector(
  Products,
  (Products) => Products.availableProducts
);

export const paginationSelector = createSelector(
  Products,
  (Products) => Products.pagination
);

export const searchSelector = createSelector(
  Products,
  (Products) => Products.search.searchText
);

export const productStockSelector = createSelector(
  Products,
  (Products) => Products.productStock
);
