import {
  Typography,
  Box,
  Slide,
  AppBar,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import ShoppingCartButton from "../ShoppingCartButton/ShoppingCartButton";
import ContactBar from "../ContactBar/ContactBar";
import AdminButton from "../AdminButton/AdminButton";
import ScheduleEventTimeBanner from "../ScheduleEventTimeBanner/ScheduleEventTimeBanner";

const MobileAppBar = () => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <div>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              Dan's Bounce City
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <ShoppingCartButton />
            <AdminButton />
          </Toolbar>
        </AppBar>
        <ContactBar />
        <ScheduleEventTimeBanner />
      </div>
    </Slide>
  );
};

export default MobileAppBar;
