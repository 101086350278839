import { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  IconButton,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LoginIcon from "@mui/icons-material/Login";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../store/applicationSlice";
import LoginDialog from "../LoginDialog/LoginDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { setAuthorized } from "../../auth/authSlice";
import { authSelector } from "../../auth/authSelector";

const AdminButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthorized = useSelector(authSelector, shallowEqual);
  const { isMobile } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleLogin = () => {
    showLogin();
    handleClose();
  };
  const handleLogout = () => {
    dispatch(setAuthorized(false));
    handleClose();
  };
  const handleAdmin = () => {
    navigate("/Admin");
    handleClose();
  };

  const showLogin = async () => {
    await dispatch(
      openDialog({
        children: <LoginDialog />,
      })
    );
  };
  return (
    <div>
      {isAuthorized ? (
        <>
          <IconButton style={{ color: "white" }} onClick={handleClick}>
            <AccountCircleIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: isMobile ? "top" : "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: isMobile ? "top" : "bottom",
              horizontal: "center",
            }}
          >
            <List>
              <ListItem divider={true} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText>User Profile</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem divider={true} disablePadding>
                <ListItemButton onClick={handleAdmin}>
                  <ListItemIcon>
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText>Admin</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Log Out</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </>
      ) : (
        <IconButton style={{ color: "white" }} onClick={handleLogin}>
          <LoginIcon />
          <Typography> Log In</Typography>
        </IconButton>
      )}
    </div>
  );
};

export default AdminButton;
