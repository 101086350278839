import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { itemsSelector } from "../../store/ShoppingCart/shoppingCartSelector";
import {
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import currency from "currency.js";
import {
  removeItem,
  setItems,
} from "../../store/ShoppingCart/shoppingCartSlice";
import { createScheduleProduct } from "../../services/ScheduleEvent/scheduleEventService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { eventTimeSelector } from "../../store/applicationSelector";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { openDialog, setEventTime } from "../../store/applicationSlice";
import DeliveryDetailsDialog from "../DeliveryDetailsDialog/DeliveryDetailsDialog";

const BookingPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const scheduledEvent = useSelector(eventTimeSelector, shallowEqual);
  const { startTime, endTime } = scheduledEvent;
  const eventDays =
    new Date(endTime).getDay() - new Date(startTime).getDay() + 1;
  const base = "https://dmbstore.blob.core.windows.net/bouncehouse/images/";
  const checkoutItems = useSelector(itemsSelector, shallowEqual);
  const productIds = checkoutItems.map((item) => item.id);
  const handleRemove = (product) => {
    dispatch(removeItem(product));
  };

  const handleBooking = () => {
    if (productIds.length === 0) {
      alert("The cart is empty. Please select products and try booking again.");
      return;
    }
    dispatch(
      openDialog({
        children: <DeliveryDetailsDialog />,
      })
    );
  };

  return (
    <div className="md:w-2/3 mx-auto">
      <Typography className=" w-1/2 mx-auto" variant="h4">{`Total: $${currency(
        checkoutItems.reduce((sum, item) => sum + item.price * eventDays, 0)
      )}`}</Typography>
      {checkoutItems.map((item) => (
        <>
          <div className="flex justify-between">
            <div className="flex">
              <div>
                <img
                  src={`${base}${item.imagePath}`}
                  width={100}
                  height={100}
                />
              </div>
            </div>
            <div>
              <div>{item?.name}</div>
            </div>
            <div>
              <div>{currency(item?.price).format()}</div>
            </div>
            <div>
              <div>{`x ${eventDays} day(s)`}</div>
            </div>
            <div>
              <div>{item?.price * eventDays}</div>
            </div>
            <div>
              <div>
                <IconButton
                  onClick={() => handleRemove(item)}
                  disabled={loading}
                >
                  <DeleteIcon className="text-red-500" />
                </IconButton>
              </div>
            </div>
          </div>
          <Divider />
        </>
      ))}
      <Button color="primary" variant="contained" onClick={handleBooking}>
        {loading ? <CircularProgress /> : "Complete Booking"}
      </Button>
    </div>
  );
};

export default BookingPage;
