import { Typography, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { closeDialog } from "../../store/applicationSlice";

const CreateAccountDialog = () => {
  const [account, { onChange }] = useForm();
  const dispatch = useDispatch();
  const handleCreateAccount = () => {};
  const handleCancel = () => {
    dispatch(closeDialog());
  };
  return (
    <>
      <div className="mx-auto">
        <Typography variant="h5">Create Account</Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 m-8">
        <div>
          <TextField
            name="firstName"
            label="First Name"
            value={account?.firstName}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="lastName"
            label="Last Name"
            value={account?.lastName}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="email"
            label="Email"
            value={account?.email}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="cellPhone"
            label="Cell Phone"
            value={account?.cellPhone}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="password"
            type="password"
            label="Password"
            value={account?.password}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="confirmPassword"
            type="confirmPassword"
            label="Confirm Password"
            value={account?.confirmPassword}
            onChange={onChange}
          />
        </div>
        <div className="md:col-span-2">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleCreateAccount}
          >
            Create
          </Button>
        </div>
        <div className="md:col-span-2">
          <Button fullWidth color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateAccountDialog;
