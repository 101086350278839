import { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  addItem,
  removeItem,
} from "../../store/ShoppingCart/shoppingCartSlice";
import { eventTimeSelector } from "../../store/applicationSelector";
import SelectEventDialog from "./SelectEventTimeDialog";
import { openDialog } from "../../store/applicationSlice";
import { itemsSelector } from "../../store/ShoppingCart/shoppingCartSelector";

const AddProductButton = ({ product }) => {
  const eventTime = useSelector(eventTimeSelector, shallowEqual);
  const shoppingCartItems = useSelector(itemsSelector, shallowEqual);
  const dispatch = useDispatch();
  const [count, setCount] = useState(
    shoppingCartItems.filter((x) => x.id === product.id)?.length
  );
  const isInCart = shoppingCartItems.some((x) => x.id === product.id);

  const handleAdd = () => {
    if (eventTime?.startTime && eventTime?.endTime) {
      dispatch(addItem(product));
      handleIncrement(1);
    } else {
      dispatch(
        openDialog({
          children: <SelectEventDialog />,
        })
      );
    }
  };

  const handleRemove = () => {
    dispatch(removeItem(product));
    handleIncrement(-1);
  };

  const handleIncrement = (amount) => {
    setCount((prev) => prev + amount);
  };

  return (
    <Fab variant="extended" size="medium" color="primary">
      {isInCart ? (
        <div className="flex space-x-4">
          <RemoveIcon onClick={handleRemove} />
          Remove
        </div>
      ) : (
        <>
          <AddIcon onClick={handleAdd} /> Add
        </>
      )}
    </Fab>
  );
};
export default AddProductButton;
