import moment from "moment";

export const getCurrentDateTime = () => {
  var date = moment().format("YYYY-MM-DD");
  var time = moment().format("HH:mm");
  const currentTime = `${date}T${time}`;
  return currentTime;
};

export const formatDate = (jsonDate) => {
  return moment(jsonDate).format("MM-DD-YYYY hh:mm A");
};

export const isSameDay = (date1, date2) => {
  return (
    moment(date1).format("MM-DD-YYYY") === moment(date2).format("MM-DD-YYYY")
  );
};

export const getTimeFromDate = (date) => {
  return moment(date).format("hh:mm A");
};
