import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

const localizer = momentLocalizer(moment);
// const allViews = Object.keys(BigCalendar.Views).map((k) => Calendar.Views[k]);

const CustomCalendar = () => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        step={60}
        style={{ height: 250 }}
        startAccessor="start"
        endAccessor="end"
        // views={allViews}
        defaultDate={new Date(2022, 6, 1)}
      />
    </div>
  );
};

export default CustomCalendar;
