import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Card, CardHeader, Button, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { CardContent } from "@mui/material";
import { BackButton } from "./BackButton";
import { Title } from "../../helpers/stringExtensions.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { createTheme } from "@mui/material";
// import { useButtonStyles } from "../../styles/buttonStyle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import ImportButton from "./ImportButton";
import SearchBar from "../Common/SearchBar";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    noSelect,
    noActions,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ position: "sticky", zIndex: "99", top: "0" }}>
      <TableRow style={{ position: "sticky", zIndex: "99" }}>
        {!noSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {!noActions && (
          <TableCell
            key={"action"}
            align={true ? "right" : "left"}
            padding={false ? "none" : "default"}
            sortDirection={false ? order : false}
          >
            <TableSortLabel
              active={false}
              direction={false ? order : "asc"}
              onClick={createSortHandler("action")}
            >
              {"Action"}
              {false ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.func.isRequired,
};

// const useToolbarStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === "light"
//       ? {
//           color: theme.palette.secondary.main,
//           //backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: "1 1 100%",
//   },
// }));

const EnhancedTableToolbar = (props) => {
  const downloadRef = useRef(null);
  const classes = {};
  const {
    numSelected,
    tableTitle,
    onAddClick,
    isSuperAdmin,
    noAdd,
    rows,
    selected,
    removeSelected,
    importstate,
    noExport,
  } = props;

  const handleDeleteSelected = () => {
    removeSelected(selected);
  };
  return (
    <Toolbar className={clsx(classes.root)}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {""}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            className="color-red-500"
            aria-label="delete"
            onClick={handleDeleteSelected}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {!noExport && (
            <>
              {importstate && (
                <div className="mr-4">
                  <ImportButton importstate={importstate} />
                </div>
              )}

              <div className="mr-4">
                <Button color="primary" variant="contained">
                  <CSVLink
                    ref={downloadRef}
                    filename={`${tableTitle}.csv`}
                    data={rows.map((row) => {
                      let updatedRow = { ...row };
                      delete updatedRow.id;
                      delete updatedRow.isDeleted;
                      return updatedRow;
                    })}
                  >
                    Export
                  </CSVLink>
                </Button>
              </div>
              {!noAdd && (
                <Tooltip title="Add Item">
                  <IconButton
                    aria-label="add iten"
                    disabled={!isSuperAdmin}
                    onClick={onAddClick}
                  >
                    <Fab
                      size="small"
                      aria-label="Add"
                      className={classes.fab}
                      color="primary"
                    >
                      <AddIcon />
                    </Fab>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   paper: {
//     width: "100%",
//     marginBottom: theme.spacing(1),
//   },
//   table: {
//     minWidth: 750,
//   },
//   container: {
//     maxHeight: 600,
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: "rect(0 0 0 0)",
//     height: 1,
//     margin: -1,
//     overflow: "hidden",
//     padding: 0,
//     position: "absolute",
//     top: 20,
//     width: 1,
//   },
// }));

export const CustomTable = (props) => {
  const {
    tableTitle,
    search,
    cellHeads,
    deleteData,
    handleAdd,
    handleEdit,
    isSuperAdmin,
    noAdd,
    contextPage,
    contextRowPerPage,
    rows,
    removeSelected,
    importstate,
    noActions,
    noSelect,
    noSearch,
    ordering,
    onRowClick,
    noExport,
    filterComponents,
    children,
  } = props;
  const { paginationState, setPageSize, setPageNumber } = props.pagination;
  const [customTableTitle, setCustomTableTitle] = useState(props.tableTitle);
  const classes = {};
  const [order, setOrder] = useState(ordering?.direction || "asc");
  const [orderBy, setOrderBy] = useState(ordering?.field || "Id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(contextPage ? contextPage : 0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    contextRowPerPage ? contextRowPerPage : 5
  );
  const [openAddCompanyUser, setOpenAddCompanyUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  //   const buttonStyles = useButtonStyles(createTheme());
  const dispatch = useDispatch();
  const updateActions = () => {
    setActions([
      {
        icon: <PersonAddIcon />,
        name: `Add ${tableTitle}`,
        sourceType: tableTitle,
      },
    ]);
  };

  const toggleAddCompanyUser = () => {
    setOpenAddCompanyUser(!openAddCompanyUser);
  };

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (noSelect) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleEditClick = (event, id) => {
    if (handleEdit) {
      handleEdit(id);
    } else {
      navigate(`/admin/${tableTitle}/edit/${id}`);
    }
  };

  const handleRowClick = (id) => {
    if (onRowClick) {
      onRowClick(id);
    }
  };

  const handleAddClick = (action) => {
    switch (action.sourceType) {
      case tableTitle:
        if (handleAdd) {
          handleAdd();
        } else {
          navigate(`/admin/${tableTitle}/add/0`);
        }
        break;
      default:
        navigate(`/distribution`);
        break;
    }
  };

  const onAddClick = () => {
    if (handleAdd) {
      handleAdd();
    } else {
      navigate(`/admin/${tableTitle}/add/0`);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
    dispatch(setPageNumber(0));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleActionClick = (action) => {
    navigate(`/table/${customTableTitle}/add/0`);
  };

  const handleDelete = async () => {
    await deleteData(rowToDelete.id);
    setSelected([]);
    //loadData();
    setRowToDelete(null);
  };

  const handleDeleteClick = async (event, row) => {
    setRowToDelete(row);
    setShowConfirmDelete(true);
  };

  const handleRemoveSelected = (selected) => {
    removeSelected(selected);
    setSelected([]);
  };

  const getCurrentDate = () => {
    var date = moment().format("MM/DD/YYYY");
    return date;
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    paginationState.pageSize -
    Math.min(
      paginationState.pageSize,
      rows.length - paginationState.pageNumber * paginationState.pageSize
    );

  return (
    <>
      <Card>
        <CardHeader
          title={Title(tableTitle)}
          action={<BackButton />}
          //subheader={noSearch || <SearchBar search={search} />}
        />
        <CardContent>
          <div className={classes.root}>
            {children}
            {/* <Paper>
              <TextField
                id="datetime-local"
                label="Start Date"
                type="date"
                defaultValue={getCurrentDate()}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={() => {}}
              />
              <TextField
                id="datetime-local"
                label="End Date"
                type="date"
                defaultValue={getCurrentDate()}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={() => {}}
              />
              <TextField label="Store" value="" />
            </Paper> */}
            <Paper className={classes.paper}>
              {isLoading && <LinearProgress />}
              <EnhancedTableToolbar
                numSelected={selected.length}
                selected={selected}
                onAddClick={onAddClick}
                removeSelected={handleRemoveSelected}
                isSuperAdmin={isSuperAdmin}
                noAdd={noAdd}
                rows={rows}
                tableTitle={tableTitle}
                importstate={importstate}
                noActions={noActions}
              />
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-labelledby="tableTitle"
                  // size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headCells={cellHeads}
                    noActions={noActions}
                    noSelect={noSelect}
                  />
                  {!isLoading && (
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          paginationState.pageNumber * paginationState.pageSize,
                          paginationState.pageNumber *
                            paginationState.pageSize +
                            paginationState.pageSize
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              onClick={() => handleRowClick(row.id)}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {!noSelect && (
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      handleClick(event, row.id)
                                    }
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                              )}
                              {cellHeads.map((cell) => {
                                return (
                                  <TableCell
                                    align={cell.numeric ? "right" : "center"}
                                  >
                                    {row[cell.id]}
                                  </TableCell>
                                );
                              })}
                              {!noActions && (
                                <TableCell align="right">
                                  <IconButton
                                    color="primary"
                                    aria-label="remove group"
                                    component="span"
                                    onClick={(event) =>
                                      handleEditClick(event, row.id)
                                    }
                                  >
                                    <Tooltip title="edit item">
                                      <EditOutlinedIcon />
                                    </Tooltip>
                                  </IconButton>
                                  <IconButton
                                    color="secondary"
                                    aria-label="remove group"
                                    component="span"
                                    disabled={!isSuperAdmin}
                                    onClick={(event) =>
                                      handleDeleteClick(event, row)
                                    }
                                  >
                                    <Tooltip title="remove item">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                className="sticky"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={paginationState.pageSize}
                page={paginationState.pageNumber}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <SpeedDial
              ariaLabel="Add item speed dial"
              icon={<SpeedDialIcon />}
              onClose={toggleAddCompanyUser}
              onOpen={toggleAddCompanyUser}
              open={openAddCompanyUser}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  tooltipTitle={action.name}
                  tooltipOpen
                  icon={action.icon}
                  onClick={() => handleAddClick(action)}
                />
              ))}
            </SpeedDial> */}
          </div>
        </CardContent>
      </Card>
      {showConfirmDelete && (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "100px" }}
          title="Are you sure?"
          onConfirm={() => {
            handleDelete();
            setShowConfirmDelete(false);
          }}
          onCancel={() => {
            setShowConfirmDelete(false);
            setRowToDelete(null);
            setSelected([]);
          }}
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          //confirmBtnCssClass={`${buttonStyles.button} ${buttonStyles.danger}`}
          //cancelBtnCssClass={`${buttonStyles.button}`}
          showCancel
        >
          <Typography gutterBottom={true} variant="subtitle2">
            Are you sure you want to delete this item?
          </Typography>
        </SweetAlert>
      )}
    </>
  );
};
