import axios from "axios";
import { apiUrl } from "../../config/apiConfig";

const endpoint = `${apiUrl}/api/pagevisited`;

export const addPageVisited = async (pageVisitedPayload) => {
  return await axios.post(`${endpoint}`, pageVisitedPayload);
};

export const getUUID = () => {
  let uuid = localStorage.getItem("uuid");
  return uuid;
};

export const setUUID = (uuid) => {
  localStorage.setItem("uuid", uuid);
};

export const deleteUUID = () => {
  localStorage.removeItem("uuid");
};
