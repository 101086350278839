import { useState } from "react";
import useDeepCompareEffect from "./useDeepCompareEffect";
import _ from "lodash";

export function useValidator(
  validateFunc,
  validateObject,
  options,
  constrains = null
) {
  const [shouldShowErrors, setShouldShowErrors] = useState(false);
  const [validationResult, setValidationResult] = useState(null);

  useDeepCompareEffect(() => {
    if (validateFunc) {
      const _result = validateFunc(validateObject, constrains);
      setValidationResult(_result);
    }
  }, [shouldShowErrors, validateObject, validateFunc]);

  const returnObject = {
    validator: {
      shouldShowErrors,
      isValid: () => {
        if (!returnObject.validator.result) {
          return true;
        }
        return _.isEqual(returnObject.validator.result, {});
      },
      result: validationResult || {},
    },
    setShouldShowErrors: (input) => {
      if (validationResult) {
        if (options && options.switchToTab) {
          options.switchToTab();
        }
      }
      setShouldShowErrors(input);
    },
  };
  return returnObject;
}
