import { useState } from "react";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const CommonDatePicker = (props) => {
  // const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        // label="Date&Time picker"
        // value={value}
        // onChange={handleChange}
        renderInput={(params) => <TextField variant="standard" {...params} />}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CommonDatePicker;
