import { Button, Link } from "@mui/material";
import ShoppingCartButton from "../ShoppingCartButton/ShoppingCartButton";
import AdminButton from "../AdminButton/AdminButton";
import { useNavigate } from "react-router-dom";
import ContactBar from "../ContactBar/ContactBar";
import ScheduleEventTimeBanner from "../ScheduleEventTimeBanner/ScheduleEventTimeBanner";
import "./NavBar.css";

const links = [
  { title: "Home", path: "" },
  // { title: "Rentals", path: "rentals" },
  // { title: "Info", path: "info" },
  { title: "Contact Us", path: "contactus" },
];
const NavBar = () => {
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/booking");
  };

  return (
    <>
      <div className="nav-bar">
        <div className="nav-bar-content flex justify-between">
          <div>Dan's Bounce City</div>
          <div className="flex justify-center space-x-4">
            {links.map((link, key) => (
              <Link
                key={key}
                underline="none"
                color="#ffffff"
                component="button"
                onClick={() => {
                  navigate(link.path);
                }}
              >
                {link.title}
              </Link>
            ))}
          </div>
          <div className="flex space-x-8">
            <ShoppingCartButton />
            <Button variant="contained" color="warning" onClick={handleBookNow}>
              Book Now
            </Button>
            <AdminButton />
          </div>
        </div>
      </div>
      <ContactBar />
      <ScheduleEventTimeBanner />
    </>
  );
};

export default NavBar;
