import { Button, Typography, TextField, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import useForm from "../../hooks/useForm";
import { useValidator } from "../../hooks/validatorHook";
import { createScheduleProduct } from "../../services/ScheduleEvent/scheduleEventService";
import { eventTimeSelector } from "../../store/applicationSelector";
import { closeDialog, setEventTime } from "../../store/applicationSlice";
import { itemsSelector } from "../../store/ShoppingCart/shoppingCartSelector";
import { setItems } from "../../store/ShoppingCart/shoppingCartSlice";
import { deliveryInfoValidator } from "../../validations/deliveryInfoValidator";

const DeliveryDetailsDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const scheduledEvent = useSelector(eventTimeSelector, shallowEqual);
  const { startTime, endTime } = scheduledEvent;
  const [deliveryInfo, { onChange }] = useForm({ state: "TX" });
  const checkoutItems = useSelector(itemsSelector, shallowEqual);
  const productIds = checkoutItems.map((item) => item.id);

  const { validator, setShouldShowErrors } = useValidator(
    deliveryInfoValidator,
    deliveryInfo
  );

  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };

  const handleCreatedeliveryInfo = async () => {
    if (!validateData()) return;

    const scheduleProduct = {
      scheduleEvent: {
        clientId: 1,
        startDateTime: startTime,
        endDateTime: endTime,
        scheduledBy: "dmendoza",
        customerId: 1,
        deliveryInfo: deliveryInfo,
      },
      productIds: productIds,
    };
    try {
      setLoading(true);
      await createScheduleProduct(scheduleProduct);
      enqueueSnackbar("event has been xscheduled", { variant: "success" });
      swal(
        "Event has been scheduled",
        `Please check you email for confirmation`,
        "success"
      );
      dispatch(setItems([]));
      dispatch(
        setEventTime({
          startTime: null,
          endTime: null,
        })
      );
      navigate("/");
      dispatch(closeDialog());
    } catch (error) {
      enqueueSnackbar("Oops! something went wrong", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    dispatch(closeDialog());
  };
  return (
    <>
      <div className="mx-auto">
        <Typography variant="h5">Delivery Information</Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 m-8">
        <div>
          <TextField
            name="firstName"
            label="First Name"
            required
            value={deliveryInfo?.firstName}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["firstName"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["firstName"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="lastName"
            label="Last Name"
            required
            value={deliveryInfo?.lastName}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["lastName"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["lastName"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="email"
            label="Email"
            type="email"
            required
            value={deliveryInfo?.email}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["email"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["email"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="cellPhone"
            label="Cell Phone"
            required
            value={deliveryInfo?.cellPhone}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["cellPhone"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["cellPhone"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="address"
            label="Address"
            required
            value={deliveryInfo?.address}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["address"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["address"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="suite"
            label="Suite/Apt"
            value={deliveryInfo?.suite}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="city"
            label="City"
            required
            value={deliveryInfo?.city}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["city"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["city"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="state"
            label="State"
            required
            disabled
            value={deliveryInfo?.state}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["state"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["state"]
                : null
            }
          />
        </div>
        <div>
          <TextField
            name="zipCode"
            label="Zip Code"
            required
            value={deliveryInfo?.zipCode}
            onChange={onChange}
            error={
              validator
                ? validator.shouldShowErrors && !!validator.result["zipCode"]
                : false
            }
            helperText={
              validator
                ? validator.shouldShowErrors && validator.result["zipCode"]
                : null
            }
          />
        </div>
        <div className="md:col-span-2">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleCreatedeliveryInfo}
          >
            {loading ? <CircularProgress /> : "Complete Booking"}
          </Button>
        </div>
        <div className="md:col-span-2">
          <Button fullWidth color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeliveryDetailsDialog;
