import ProductCard from "../ProductCard/ProductCard";
import "./ProductList.css";
import { useEffect, useState } from "react";
import { getClientProducts } from "../../services/Products/productService";
import { shallowEqual, useSelector } from "react-redux";
import { eventTimeSelector } from "../../store/applicationSelector";
import { LinearProgress, Typography } from "@mui/material";

const Reserve = () => {
  const eventTime = useSelector(eventTimeSelector, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    loadProducts();
  }, [eventTime]);

  const loadProducts = async () => {
    try {
      setLoading(true);
      const { data } = await getClientProducts(
        eventTime?.startTime,
        eventTime?.endTime
      );
      setProducts(data);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <div>
          <LinearProgress />
          <div className="w-2/3 mx-auto">
            <Typography>Loading Inventory...</Typography>
          </div>
        </div>
      ) : (
        <div className="reserve-container grid md:grid-cols-4 sm:grid-cols-2 gap-8 w-2/3 mx-auto">
          {products.map((product, key) => {
            return <ProductCard key={key} product={product} />;
          })}
        </div>
      )}
    </>
  );
};

export default Reserve;
