import { combineReducers } from "@reduxjs/toolkit";
import { application } from "./applicationSlice";
import { shoppingCart } from "./ShoppingCart/shoppingCartSlice";

export const reducers = combineReducers({
    application,
    shoppingCart
});




 