export const adminList = [
  {
    title: "Customers",
    path: "/admin/customers",
  },
  {
    title: "Products",
    path: "/admin/products",
  },
];
