export const PAGES = {
  INDEX: "/",
  HOME: "Home",
  BOOKING: "Booking",
  CONTACTUS: "ContactUs",
};

export const PRIVATE = {
  ADMIN: "Admin",
};
export const ADMIN_PAGES = {
  CUSTOMERS: "Customers",
  PRODUCTS: "admin/products",
};

export const ADMIN_EDIT_PAGES = {
  PRODUCTSWITHMODE: "/admin/products/:mode",
  PRODUCTSWITHID: "/admin/products/:mode/:productId",
};

export const SUPERADMIN_PAGES = {};
