import { Badge, IconButton } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useSelector, shallowEqual } from "react-redux";
import { itemsSelector } from "../../store/ShoppingCart/shoppingCartSelector";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const ShoppingCartButton = () => {
  const items = useSelector(itemsSelector, shallowEqual);

  return (
    <StyledBadge badgeContent={items.length} color="warning">
      <IconButton>
        <ShoppingCartOutlinedIcon style={{ color: "white" }} />
      </IconButton>
    </StyledBadge>
  );
};

export default ShoppingCartButton;
