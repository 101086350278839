import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../images/Placeholder.jpg";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const AdminCard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { admin } = props;

  const handleClick = () => {
    navigate(admin.path);
  };

  return (
    <Card className={classes.root} onClick={handleClick}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="product"
          height="70"
          image={Placeholder}
          title="product"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {admin.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AdminCard;
