import Admin from "../components/Admin/Admin";
import ProductLog from "../components/Product/ProductLog";
import ProductEdit from "../components/Product/ProductEdit";
import ProductTest from "../components/Product/ProductTest";
import { PRIVATE, ADMIN_PAGES, ADMIN_EDIT_PAGES } from "../shared/urlTemplates";

const privateRoutes = [
  { path: PRIVATE.ADMIN, component: <Admin /> },
  { path: ADMIN_EDIT_PAGES.PRODUCTS, component: <ProductEdit /> },
  { path: ADMIN_EDIT_PAGES.PRODUCTSWITHMODE, component: <ProductEdit /> },
  { path: ADMIN_EDIT_PAGES.PRODUCTSWITHID, component: <ProductEdit /> },
  { path: ADMIN_PAGES.PRODUCTS, component: <ProductLog /> },
];

export default privateRoutes;
