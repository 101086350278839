import React, { useRef } from "react";
import { Button } from "@mui/material";
// import { CSVReader } from "react-papaparse";

const ImportButton = ({ importstate }) => {
  const buttonRef = useRef();
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const items = data.map((item) => {
      if (item.data[0] != "") return importstate.createInstance(item.data);
    });
    items.splice(0, 1);
    importstate.importItems(items);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <>
      {/* <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <aside>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={handleOpenDialog}
            >
              Import
            </Button>
          </aside>
        )}
      </CSVReader> */}
    </>
  );
};

export default ImportButton;
