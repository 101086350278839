export const getEventTime = () => {
  var eventTime = localStorage.getItem("eventTime");
  if (eventTime) return JSON.parse(eventTime);
  return {
    startTime: null,
    endTime: null,
  };
};

export const setEventTime = (eventTime) => {
  const eventTimeString = JSON.stringify(eventTime);
  localStorage.setItem("eventTime", eventTimeString);
};

export const deleteEventTime = () => {
  localStorage.removeItem("eventTime");
};
