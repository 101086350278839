import "./JumboCenter.css";
import SetEventTimeButton from "../SetEventButton/SetEventTimeButton";
import { Typography } from "@mui/material";

const JumboCenter = () => {
  return (
    <div className="jumbo-center flex justify-center space-x-4">
      <div className="jumbo-message flex flex-col items-center justify-center w-max py-0 my-0">
        <div className="jumbo-text mx-auto">
          <Typography
            sx={{
              fontSize: {
                lg: 65,
                md: 55,
                sm: 45,
                xs: 30,
              },
            }}
          >
            Welcome to Dan's Bounce City
          </Typography>
        </div>
        <div className="jumbo-text mx-auto">
          <Typography variant="h5">Set the time of your event</Typography>
        </div>
        <div className="jumbo-text justify-center mx-auto">
          <SetEventTimeButton />
        </div>
      </div>
    </div>
  );
};
export default JumboCenter;
