import TopAppBar from "../../components/TopAppBar/TopAppBar";
import BottomAppBar from "../../components/BottomAppBar/BottomAppBar";
import MobileAppBar from "../../components/MobileAppBar/MobileAppBar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useAnalyticsEventTracker from "../../gatracking/useAnalyticsEventTracker";
import { usePageTracking } from "../../hooks/usePageTracking";

const AppBarWrapper = ({ children }) => {
  usePageTracking();
  const gaEventTracker = useAnalyticsEventTracker("App");
  const { isMobile } = useWindowDimensions();
  return (
    <>
      <div className="header sticky top-0">
        {!isMobile ? <TopAppBar /> : <MobileAppBar />}
      </div>
      {children}
      {isMobile && <BottomAppBar />}
    </>
  );
};

export default AppBarWrapper;
