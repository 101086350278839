import NavBar from "../../components/NavBar/NavBar";

const TopAppBar = () => {
  return (
    <>
      <NavBar />
    </>
  );
};

export default TopAppBar;
