import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  addPageVisited,
  getUUID,
  setUUID,
} from "../services/PageVisited/pageVisitedService";
import uuid from "react-uuid";
import ReactGA from "react-ga";

function initialiseAnalytics() {
  const TRACKING_ID = "G-787LFGL13T";
  ReactGA.initialize(TRACKING_ID);
}

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initialiseAnalytics();
    setInitialized(true);
    let id = getUUID();
    if (!id) setUUID(uuid());
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
    const logPageVisited = async () => {
      await addPageVisited({
        uniqueUserId: getUUID(),
        pageRoute: location.pathname,
        dateVisited: new Date(),
      });
    };
    logPageVisited();
  }, [initialized, location]);
};
