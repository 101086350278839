import Home from "../pages/Home/Home";
import { PAGES, ADMIN_PAGES } from "../shared/urlTemplates";
import ContactUs from "../pages/ContactUs/ContactUs";
import BookingPage from "../components/BookingPage/BookingPage";

const componentRoutes = [
  { path: PAGES.INDEX, componet: <Home /> },
  { path: PAGES.HOME, componet: <Home /> },
  { path: ADMIN_PAGES.CUSTOMERS, component: <Home /> },
  { path: PAGES.CONTACTUS, component: <ContactUs /> },
  { path: PAGES.BOOKING, component: <BookingPage /> },
];

export default componentRoutes;
