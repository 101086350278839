import React from "react";
import { adminList } from "./AdminList";
import { Grid } from "@mui/material";
import AdminCard from "./AdminCard";

const Admin = () => {
  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        {adminList.map((admin, key) => {
          return (
            <div className="">
              <AdminCard key={key} admin={admin} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Admin;
