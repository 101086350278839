import {
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { BackButton } from "../../components/Common/BackButton";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { productValidator } from "../../validations/productValidator";
import {
  getProductById,
  saveProduct,
  createProduct,
} from "../../services/Products/productService";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import useForm from "../../hooks/useForm";
import { getProductByIdAsync } from "./store/productSlice";
import { productSelector, productStockSelector } from "./store/productSelector";
import CustomCalendar from "../../components/Common/CustomCalendar";
// const useStyles = makeStyles((theme) => ({
//   fab: {
//     position: "fixed",
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
// }));

const ProductEdit = (props) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  // const classes = useStyles();
  const theme = useTheme();
  const { children, value, index, ...other } = props;
  const productState = useSelector(productSelector, shallowEqual);
  const storeStockState = useSelector(productStockSelector, shallowEqual);
  const [product, eventHandlers] = useForm(productState);
  const {
    onChange,
    onSelectChange,
    onCurrencyChange,
    setForm: setproduct,
  } = eventHandlers;
  const { mode, productId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { validator, setShouldShowErrors } = useValidator(
    productValidator,
    product
  );

  const isSuperAdmin = true;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (mode == "edit") {
      dispatch(getProductByIdAsync(productId));
    }
    return () => {};
  }, []);

  useEffect(() => {
    setproduct(productState);
  }, [productState]);

  const loadProduct = async () => {
    try {
      const { data: menuResult } = await getProductById(productId);
      if (menuResult) {
        setproduct(menuResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };

  const onSave = async () => {
    if (!validateData()) return;
    let success = false;
    try {
      // const productToSave = { ...product, productStockLevels: storeStockState };
      if (Number(productId) > 0) {
        const { data } = await saveProduct(product.id, product);
        success = data;
      } else {
        const { data } = await createProduct(product);
        success = data;
      }
      if (success) {
        enqueueSnackbar("successfully saved product item", {
          variant: "success",
        });
        dispatch(getProductByIdAsync(productId));
      } else {
        enqueueSnackbar("failed to save product item", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("failed to save product item.", { variant: "error" });
    }
  };

  return (
    <Card>
      <CardHeader
        title={`${mode === "edit" ? "Edit" : "Add"} Product Items`}
        action={
          <>
            <BackButton />
          </>
        }
      />
      <CardContent>
        <Divider />
        <br />
        <Grid container direction="row" spacing={4} md={12}>
          <Grid item xs={12} md={8}>
            <CustomCalendar />
          </Grid>
          <Grid item xs={12} md={4}>
            <div></div>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              required
              disabled={!isSuperAdmin}
              defaultValue={product.name}
              value={product.name || ""}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["name"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["name"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="productCode"
              label="Product Code"
              variant="outlined"
              fullWidth
              defaultValue={product.productCode}
              value={product.productCode || ""}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors &&
                    !!validator.result["productCode"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors &&
                    validator.result["productCode"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="price"
              label="Price"
              variant="outlined"
              fullWidth
              value={product.price || 0.0}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["price"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["price"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              disabled={!isSuperAdmin}
              defaultValue={product.description}
              value={product.description || ""}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors &&
                    !!validator.result["description"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors &&
                    validator.result["description"]
                  : null
              }
            />
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} md={6} lg={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={!isSuperAdmin}
          >
            Save
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductEdit;
