import { useEffect } from "react";
import "./App.css";
import AppBarWrapper from "./components/AppBarWrapper/AppBarWrapper";
import "./components/Header/Header.css";
import componentRoutes from "./routes/componentRoutes";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import GlobalDialog from "./components/GlobalDialog/GlobalDialog";
import privateRoutes from "./routes/privateRoutes";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

function App() {
  return (
    <Router>
      <AppBarWrapper>
        <Routes>
          <Route index element={<Home />} />
          {componentRoutes.map((prop, key) => {
            return (
              <Route key={key} path={prop.path} element={prop.component} />
            );
          })}
          {privateRoutes.map((prop, key) => {
            return (
              <Route
                key={key}
                path={prop.path}
                element={<PrivateRoute>{prop.component}</PrivateRoute>}
              />
            );
          })}
        </Routes>
        <GlobalDialog />
      </AppBarWrapper>
    </Router>
  );
}

export default App;
