import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { reducers } from "./";
import { application } from "./applicationSlice";
import { shoppingCart } from "./ShoppingCart/shoppingCartSlice";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import { withReduxStateSync } from "redux-state-sync";
import { auth } from "../auth/authSlice";
import { products } from "../components/Product/store/productSlice";

export default function configureStore(history, initialState) {
  const middleware = [
    thunk,
    routerMiddleware(history),
    createStateSyncMiddleware({}),
  ];

  const rootReducer = withReduxStateSync(
    combineReducers({
      ...reducers,
      auth,
      application,
      shoppingCart,
      products,
      router: connectRouter(history),
    })
  );

  const enhancers = [];
  const windowIfDefined = typeof window === "undefined" ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  initStateWithPrevTab(store);
  return store;
}
