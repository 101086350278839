import { Button, DialogContent, DialogActions } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CommonDatePicker from "../Common/DatePicker";
import useForm from "../../hooks/useForm";
import { getCurrentDateTime } from "../../helpers/datetimeHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setEventTime, closeDialog } from "../../store/applicationSlice";
import { eventTimeSelector } from "../../store/applicationSelector";

const SetEventTimeDialog = () => {
  const dispatch = useDispatch();
  const eventTime = useSelector(eventTimeSelector, shallowEqual);
  const [eventInfo, { onDateChange }] = useForm({
    startTime: eventTime?.startTime || getCurrentDateTime(),
    endTime: eventTime?.endTime || getCurrentDateTime(),
  });

  const handleSetTime = () => {
    dispatch(setEventTime(eventInfo));
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };
  return (
    <>
      <DialogTitle>Set Event Time</DialogTitle>
      <DialogContent>
        <div className="md:flex md:space-x-4">
          <CommonDatePicker
            name="startTime"
            label="Start Time"
            value={eventInfo.startTime}
            onChange={(value) => onDateChange("startTime", value)}
          />
          <CommonDatePicker
            name="endTime"
            label="End Time"
            value={eventInfo.endTime}
            onChange={(value) => onDateChange("endTime", value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSetTime}>
          Set Time
        </Button>
      </DialogActions>
    </>
  );
};

export default SetEventTimeDialog;
