import { Box, Fab, Toolbar, AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MobileMenuButton from "../MobileAppBar/MobileMenuButton";
import { itemsSelector } from "../../store/ShoppingCart/shoppingCartSelector";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "200px",
});

const BottomAppBar = () => {
  const navigate = useNavigate();
  const items = useSelector(itemsSelector, shallowEqual);

  const handleBookNow = () => {
    navigate("/booking");
  };
  return (
    <>
      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          {items.length > 0 && (
            <StyledFab
              color="warning"
              aria-label="add"
              variant="extended"
              onClick={handleBookNow}
            >
              Book Now
            </StyledFab>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <div className="flex space-x-8">
            <MobileMenuButton />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default BottomAppBar;
