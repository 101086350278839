import validate from "validate.js";

const productValidator = (product) =>
  validate(product, {
    name: {
      presence: { allowEmpty: false, message: "^'Name' is required" },
    },
    productCode: {
      presence: { allowEmpty: false, message: "^'Product Code' is required" },
    },
    price: {
      presence: { allowEmpty: false, message: "^'Price' is required" },
    },
  });

export { productValidator };
