import React from "react";
import { Tooltip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const BackButton = (props) => {
  const { url } = props;
  let navigate = useNavigate();
  const goBack = () => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };
  return (
    <Tooltip title="Go back" arrow>
      <Button variant="contained" color="primary" onClick={goBack}>
        <ArrowBackIcon />
      </Button>
    </Tooltip>
  );
};
