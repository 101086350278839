import { TextField, Button, Typography, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../store/applicationSlice";
import useForm from "../../hooks/useForm";
import { openDialog } from "../../store/applicationSlice";
import CreateAccountDialog from "../CreateAccountDialog/CreateAccountDialog";
import { authenticateUser } from "../../services/User/userService";
import { setAuthorized, setToken } from "../../auth/authSlice";

const LoginDialog = () => {
  const [login, { onChange, isLoading, startLoading, stopLoading }] = useForm();

  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      startLoading();
      const { data } = await authenticateUser({ ...login, clientId: 1 });

      if (data) {
        dispatch(setAuthorized(true));
        dispatch(setToken(data.token));
        dispatch(closeDialog());
      }
    } catch (error) {
    } finally {
      stopLoading();
    }
  };

  const handleCreateAccount = () => {
    dispatch(
      openDialog({
        children: <CreateAccountDialog />,
      })
    );
  };

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  return (
    <>
      <div className="mx-auto">
        <Typography variant="h5">Login</Typography>
      </div>
      <div className="flex flex-col m-8 space-y-4">
        <div>
          <TextField
            name="userName"
            label="UserName/Email"
            value={login?.userName}
            onChange={onChange}
          />
        </div>
        <div>
          <TextField
            name="password"
            type="password"
            label="Password"
            value={login?.password}
            onChange={onChange}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            "Log In"
          )}
        </Button>
        <Button onClick={handleCreateAccount}>Create Account</Button>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};
export default LoginDialog;
