import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { SearchState } from "../../../store/search/searchState";
import {
  deleteProduct,
  deleteProducts,
  searchProductByText,
  importProducts,
  searchProductsByStoreId,
  getProductById,
  getStockByProductId,
} from "../../../services/Products/productService";

const paginationState = new PaginationState("product/pagination", {});
const searchState = new SearchState("product/search", {});

export const getItemsAsync = createAsyncThunk(
  "products/getProducts",
  async (search, { rejectWithValue }) => {
    return searchProductByText(search.searchText)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getProductByIdAsync = createAsyncThunk(
  "products/getproductById",
  async (id, { dispatch, rejectWithValue }) => {
    dispatch(setSelectedProduct({}));
    return getProductById(id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const removeProductAsync = createAsyncThunk(
  "product/remove",
  async (id, { rejectWithValue, dispatch, getState }) => {
    const { products } = getState();
    return deleteProduct(id)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(getItemsAsync(products.search));
        return;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const removeProductsAsync = createAsyncThunk(
  "product/remove",
  async (selected, { rejectWithValue, dispatch, getState }) => {
    const { products } = getState();
    return deleteProducts(selected)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(getItemsAsync(products.search));
        return;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const importProductsAsync = createAsyncThunk(
  "products/import",
  async (products, { rejectWithValue, dispatch }) => {
    return importProducts(products)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(getItemsAsync());
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getStoreProductsAsync = createAsyncThunk(
  "products/getProductsForSelector",
  async (storeId, { rejectWithValue }) => {
    return searchProductsByStoreId(storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getProductStockAsync = createAsyncThunk(
  "product/product-stock",
  async (productId, { rejectWithValue }) => {
    return getStockByProductId(productId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    selectedProduct: { id: 0, name: "ALL" },
    availableProducts: [],
    pagination: paginationState.getInitialState,
    search: searchState.getInitialState,
    productStock: [],
  },
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setProductStock: (state, action) => {
      state.productStock = action.payload;
    },
    ...paginationState.reducers,
    ...searchState.reducers,
  },
  extraReducers: (builder) => {
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload || [];
    });
    builder.addCase(getProductByIdAsync.fulfilled, (state, actions) => {
      state.selectedProduct = actions.payload;
    });
    // builder.addCase(removeProductAsync.fulfilled, (state, actions) => {
    //   state.selection.checked = [];
    // });
    builder.addCase(getProductStockAsync.fulfilled, (state, actions) => {
      state.productStock = actions.payload || [];
    });
  },
});

export const {
  setPageSize,
  setPageNumber,
  setSelectedProduct,
  setSearch,
  setProductStock,
} = productsSlice.actions;

export const products = productsSlice.reducer;
