import { createSlice } from "@reduxjs/toolkit";
import {
  setEventTime as setEventTimeLS,
  getEventTime,
} from "../services/Event/eventService";

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    darkMode: false,
    isMobile: false,
    showDialog: false,
    dialogContent: {},
    eventTime: {
      startTime: getEventTime()?.startTime,
      endTime: getEventTime()?.endTime,
    },
  },
  reducers: {
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setEventTime: (state, action) => {
      state.eventTime = action.payload;
      setEventTimeLS(action.payload);
    },
    openDialog: (state, action) => {
      state.showDialog = true;
      state.dialogContent = action.payload;
    },
    closeDialog: (state, action) => {
      state.showDialog = false;
    },
  },
});

export const {
  setDarkMode,
  setIsMobile,
  setEventTime,
  openDialog,
  closeDialog,
} = applicationSlice.actions;
export const application = applicationSlice.reducer;
