import { parseToFloat } from "../helpers/currency/currencyHelper";

export class Product {
  id = 0;
  name = "";
  productCode = "";
  cost = 0;
  price = 0;
  isDeleted = false;
  impagePath = "";
  vendorId = 0;
  categoryId = 0;

  constructor(csvArray) {
    this.name = csvArray[0];
    this.productCode = csvArray[1];
    this.cost = parseToFloat(csvArray[2]);
    this.price = parseToFloat(csvArray[3]);
    this.wholeSalePrice = parseToFloat(csvArray[4]);
    this.minStock = csvArray[5];
    this.currentStock = csvArray[6];
    this.vendorId = csvArray[7];
    this.categoryId = csvArray[8];
  }
}
