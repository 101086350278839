import React, { useEffect, useState } from "react";
import { CustomTable } from "../Common/CustomTable";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { removeProductAsync, removeProductsAsync } from "./store/productSlice";
import { useSnackbar } from "notistack";
import {
  itemSelector,
  paginationSelector,
  searchSelector,
} from "../Product/store/productSelector";
import {
  setPageSize,
  setPageNumber,
  setSearch,
  getItemsAsync,
  importProductsAsync,
} from "./store/productSlice";
import { Product } from "../../models/Product";
import _ from "lodash";
import { toCurrency } from "../../helpers/currency/currencyHelper";

const productColumns = [
  {
    id: "productCode",
    numeric: true,
    disablePadding: true,
    label: "Code",
  },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "price",
    numeric: false,
    disablePadding: true,
    label: "Price",
  },
];

const ProductLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const productsState = useSelector(itemSelector, shallowEqual);
  const searchText = useSelector(searchSelector, shallowEqual);

  const products = productsState.map((product) => {
    return {
      ...product,
      cost: toCurrency(product.cost),
      price: toCurrency(product.price),
      wholeSalePrice: toCurrency(product.wholeSalePrice),
    };
  });

  const pagination = {
    paginationState,
    setPageNumber,
    setPageSize,
  };

  const handleAdd = () => {
    navigate("/admin/products/add/0");
  };
  const handeEdit = (id) => {
    navigate(`//admin/products/edit/${id}`);
  };

  const search = {
    searchText,
    searchPlaceholder: "Search by Name or Code",
    handleSearch: _.debounce((text) => {
      dispatch(setSearch(text));
    }, 500),
  };

  const importstate = {
    createInstance: (item) => {
      return new Product(item);
    },
    importItems: (items) => {
      try {
        dispatch(importProductsAsync(items));
      } catch {
        enqueueSnackbar("error importing items", { variant: "success" });
      }
      enqueueSnackbar("items successfully imported", { variant: "success" });
    },
  };

  const removeProduct = async (id) => {
    await dispatch(removeProductAsync(id));
    enqueueSnackbar("removed successfully", { variant: "success" });
  };

  const removeSelected = async (selected) => {
    await dispatch(removeProductsAsync(selected));
    enqueueSnackbar(`(${selected.length}) items removed successfully`, {
      variant: "success",
    });
  };

  useEffect(() => {
    const search = searchText ? searchText : "";
    dispatch(getItemsAsync({ searchText: search }));
  }, [searchText]);

  return (
    <>
      <CustomTable
        tableTitle="products"
        rows={products}
        cellHeads={productColumns}
        handleAdd={handleAdd}
        handeEdit={handeEdit}
        deleteData={removeProduct}
        removeSelected={removeSelected}
        isSuperAdmin={true}
        pagination={pagination}
        search={search}
        importstate={importstate}
      />
    </>
  );
};

export default ProductLog;
