import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import "./ContactBar.css";
import { Link } from "@mui/material";

const styling = {
  color: "white",
};

const ContactBar = () => {
  const handlePhone = (e) => {
    window.location.href = "tel:+12146995490";
    e.preventDefault();
  };

  const handleEmail = (e) => {
    window.location.href = "mailto:info@dansbouncecity.com";
    e.preventDefault();
  };

  return (
    <div className="contact-bar">
      <div className="contact-bar-content flex space-x-4">
        <Link
          component="button"
          to="#"
          onClick={handlePhone}
          style={styling}
          underline="none"
        >
          <div className="flex space-x-2">
            <div>
              <LocalPhoneIcon color="success" />
            </div>
            <div>(214) 699-5490</div>
          </div>
        </Link>
        <Link
          component="button"
          to="#"
          onClick={handleEmail}
          underline="none"
          style={styling}
        >
          <div className="flex space-x-2">
            <div>
              <EmailIcon color="warning" />
            </div>
            <div>info@dansbouncecity.com</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ContactBar;
