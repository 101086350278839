import { createSelector } from "reselect";

const Application = (state) => state.application;

export const darkModeSelector = createSelector(
  Application,
  (Application) => Application.darkMode
);

export const showDialogSelector = createSelector(
  Application,
  (Application) => Application.showDialog
);

export const dialogContentSelector = createSelector(
  Application,
  (Application) => Application.dialogContent
);

export const eventTimeSelector = createSelector(
  Application,
  (Application) => Application.eventTime
);
