import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { Box, Drawer, List, ListItem } from "@mui/material";

const links = [
  { title: "Home", path: "" },
  { title: "Rentals", path: "rentals" },
  { title: "Info", path: "info" },
  { title: "Contact Us", path: "contactus" },
];
const MobileMenuButton = () => {
  const navigate = useNavigate();
  const anchor = "bottom";
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, bottom: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((link, index) => (
          <ListItem button key={index} onClick={() => navigate(link.path)}>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(anchor, true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {list(anchor)}
      </Drawer>
    </>
  );
};

export default MobileMenuButton;
