import { createSlice } from "@reduxjs/toolkit";

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: {
    items: [],
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeItem: (state, action) => {
      const newItems = [...state.items];
      newItems.splice(newItems.indexOf(action.payload), 1);
      state.items = newItems;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setItems, addItem, removeItem } = shoppingCartSlice.actions;
export const shoppingCart = shoppingCartSlice.reducer;
