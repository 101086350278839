import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../store/applicationSlice";
import SetEventTimeButton from "../SetEventButton/SetEventTimeButton";

const SelectEventDialog = () => {
  const dispatch = useDispatch();

  const closeEventDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <>
      <DialogTitle>Select Event Time</DialogTitle>
      <DialogContent>
        <Typography>Please select event time before adding product</Typography>
      </DialogContent>
      <DialogActions>
        <SetEventTimeButton />
        <Button onClick={closeEventDialog}>Close</Button>
      </DialogActions>
    </>
  );
};

export default SelectEventDialog;
