import validate from "validate.js";

const deliveryInfoValidator = (eventTimes) =>
  validate(eventTimes, {
    firstName: {
      presence: { allowEmpty: false, message: "^'FirstName must not be empty" },
    },
    lastName: {
      presence: { allowEmpty: false, message: "^'Last Name must not be empty" },
    },
    email: {
      presence: { allowEmpty: false, message: "^'Email must not be empty" },
      email: {
        message: "doesn't look like a valid email",
      },
    },
    cellPhone: {
      presence: {
        allowEmpty: false,
        message: "^'Cell Phone must not be empty",
      },
    },
    address: {
      presence: { allowEmpty: false, message: "^'Address must not be empty" },
    },
    city: {
      presence: { allowEmpty: false, message: "^'City must not be empty" },
    },
    zipCode: {
      presence: { allowEmpty: false, message: "^'Zip Code must not be empty" },
    },
  });

export { deliveryInfoValidator };
