export const getAuth = () => {
  var eventTime = localStorage.getItem("authorized");
  if (eventTime) return JSON.parse(eventTime);
  return {
    startTime: null,
    endTime: null,
  };
};

export const setAuth = (eventTime) => {
  const eventTimeString = JSON.stringify(eventTime);
  localStorage.setItem("authorized", eventTimeString);
};

export const deleteAuth = () => {
  localStorage.removeItem("authorized");
};
