import {
  dialogContentSelector,
  showDialogSelector,
} from "../../store/applicationSelector";
import { Dialog } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";

const GlobalDialog = () => {
  const showDialog = useSelector(showDialogSelector, shallowEqual);
  const dialogContent = useSelector(dialogContentSelector, shallowEqual);
  const { children, fullWidth, maxWidth } = dialogContent;
  return (
    <div>
      <Dialog
        open={showDialog}
        aria-labelledby="alert-dialog-titie"
        aria-describedby="alert-dialog-describedby"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        {children}
      </Dialog>
    </div>
  );
};

export default GlobalDialog;
