let url;

const hostname = window && window.location && window.location.hostname;

if (hostname === "localhost") {
  //url = "https://posapi.geidigital.com"
  url = "https://localhost:44396";
} else if (hostname === "https://dansbouncecity.com") {
  url = "https://dandev-prod-001-webapi.azurewebsites.net";
} else {
  url = "https://dandev-prod-001-webapi.azurewebsites.net";
}

export const apiUrl = url;
