import { IconButton } from "@mui/material";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { eventTimeSelector } from "../../store/applicationSelector";
import "./ScheduleEventTimeBanner.css";
import {
  formatDate,
  isSameDay,
  getTimeFromDate,
} from "../../helpers/datetimeHelper";
import CloseIcon from "@mui/icons-material/Close";
import { setEventTime } from "../../store/applicationSlice";

const ScheduleEventTimeBanner = () => {
  const dispatch = useDispatch();
  const scheduledEvent = useSelector(eventTimeSelector, shallowEqual);
  const { startTime, endTime } = scheduledEvent;
  const handleClear = () => {
    dispatch(
      setEventTime({
        startTime: null,
        endTime: null,
      })
    );
  };
  return (
    <>
      {startTime && endTime && (
        <div className="text-center banner text-white">
          <IconButton onClick={handleClear}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          {`Event Time: ${formatDate(startTime)} -> ${
            isSameDay(startTime, endTime)
              ? getTimeFromDate(endTime)
              : formatDate(endTime)
          }`}
        </div>
      )}
    </>
  );
};

export default ScheduleEventTimeBanner;
