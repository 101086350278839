import { CircularProgress, TextField, Typography, Button } from "@mui/material";
import useForm from "../../hooks/useForm";
import "./ContactUs.css";
import { emailContactMessage } from "../../services/Email/emailService";
import { useSnackbar } from "notistack";

const ContactUs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    contact,
    { onChange, clearForm, startLoading, stopLoading, isLoading },
  ] = useForm();

  const handleSend = async () => {
    startLoading();
    try {
      await emailContactMessage(contact);
      clearForm();
      enqueueSnackbar("email sent. talk to you soon!", { variant: "success" });
    } catch {
      console.log("error");
      enqueueSnackbar("oops! something went wrong", { variant: "error" });
    } finally {
      stopLoading();
      clearForm();
    }
  };

  return (
    <>
      <div className="contact-container py-8 grid grid-cols-2 gap-4 md:w-2/3 mx-auto sm: w-10/12">
        <div className="col-span-2 mx-auto">
          <Typography variant="h4">Contact Us</Typography>
        </div>
        <p className="col-span-2 mx-auto">
          Have questions? Reach out! Don’t hesitate to give us a call or use our
          contact form to reach out to us anytime!
        </p>
        <TextField
          name="firstName"
          label="First Name"
          value={contact?.firstName || ""}
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          name="lastName"
          label="Last Name"
          value={contact?.lastName || ""}
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          name="cellPhone"
          label="Cell Phone"
          value={contact?.cellPhone || ""}
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          name="email"
          label="Email"
          value={contact?.email || ""}
          onChange={onChange}
          disabled={isLoading}
        />
        <div className="col-span-2">
          <TextField
            fullWidth
            multiline
            rows={5}
            name="message"
            label="Message"
            value={contact?.message || ""}
            onChange={onChange}
            disabled={isLoading}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Button
            fullWidth
            variant="contained"
            onClick={handleSend}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
