export class SearchState {
  constructor(searchText, extraReducers, initialState) {
    this.searchText = searchText;
  }
  getInitialState = {
    searchText: "",
    ...this.initialState,
  };
  reducers = {
    setSearch: (state, action) => {
      state.search.searchText = action.payload;
    },
  };
  extraReducers = {
    ...this.extraReducers,
  };
}
