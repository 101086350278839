import { useState } from "react";

const useForm = (initialState) => {
  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const eventHandlers = {
    onChange: ({ currentTarget }) => {
      const { name, value, checked, type } = currentTarget;
      setForm({ ...form, [name]: type === "checkbox" ? checked : value });
    },
    onSelectChange: ({ target }) => {
      const { name, value } = target;
      setForm({ ...form, [name]: value });
    },
    onCurrencyChange: ({ target }, value) => {
      const { name } = target;
      setForm({ ...form, [name]: value });
    },
    onMultiSelectChange: (value) => {
      setForm({ ...form, stores: value });
    },
    onDateChange: (name, value) => {
      setForm({ ...form, [name]: value });
    },
    clearForm: () => {
      console.log("clear");
      setForm(null);
    },
    startLoading: () => {
      setLoading(true);
    },
    stopLoading: () => {
      setLoading(false);
    },
    isLoading: loading,
    setForm,
  };
  return [form, eventHandlers];
};

export default useForm;
