import axios from "axios";
import { apiUrl } from "../../config/apiConfig";

const endpoint = `${apiUrl}/api/email`;

export const emailContactMessage = async (message) => {
  const emailRequest = buildEmailRequest(message);
  return await axios.post(`${endpoint}/contactus`, emailRequest);
};

const buildEmailRequest = (message) => {
  return {
    email: "info@dansbouncecity.com",
    emailFrom: "",
    subject: `Contact Us Message: ${message.firstName} ${message.lastName}`,
    body: `Cell: ${message.cellPhone}\nEmail: ${message.email}\nMessage: ${message.message}`,
    bodyHtml: `Cell: ${message.cellPhone}<br>Email: ${message.email}<br>Message: ${message.message}`,
  };
};
