import { createSlice } from "@reduxjs/toolkit";
import { getAuth, setAuth } from "../services/Auth/authService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    user: {},
    authorized: getAuth(),
    roles: [],
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthorized: (state, action) => {
      state.authorized = action.payload;
      setAuth(action.payload);
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setToken, setAuthorized, setUser } = authSlice.actions;

export const auth = authSlice.reducer;
