import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer flex flex-col justify-center">
      <div className="mx-auto">
        <div>&copy; 2022 Dan's Bounce Party. All Rights Reserved</div>
        <div>Powered by DanDev Solutions</div>
      </div>
    </div>
  );
};
export default Footer;
