import ProductList from "../../components/ProductList/ProductList";
import Footer from "../../components/Footer/Footer";
import JumboCenter from "../../components/JumboCenter/JumboCenter";
import ContactUs from "../../pages/ContactUs/ContactUs";
import { Divider } from "@mui/material";

const Home = () => {
  return (
    <>
      <JumboCenter />
      <ProductList />
      <Divider />
      <ContactUs />
      <Footer />
    </>
  );
};
export default Home;
